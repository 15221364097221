import { createAction } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { WebsocketEventType } from "src/app/common/models/websocket/websocket-event-type";
import { WebsocketRequestType } from "src/app/common/models/websocket/websocket-request-type.model";
import { DlgService } from "src/app/common/services/dlg.service";
import { UrlUtils } from "src/app/common/utils/url.utils";
import { WebsocketUtils } from "src/app/common/utils/websocket.utils";
import { websocketActions } from "src/app/root/store/actions/websocket.actions";
import { websocketActiveSubscriptionsSelector } from "src/app/root/store/selectors/websocket.selectors";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "./dlg.service";
import * as i3 from "@ngx-translate/core";
/**
 * Сервис для работы с веб сокетом.
 */
var OperatorWebsocketService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса работы с веб сокетом.
     *
     * @param store Хранилище состояния приложения.
     * @param dlgService Сервис для работы с диалогами.
     * @param translateService Сервис перевода.
     */
    function OperatorWebsocketService(store, dlgService, translateService) {
        var _this = this;
        /**
         * Проверка соединения провалена?
         */
        this._keepAliveField = false;
        /**
         * Максимальное время ожидания Keep Alive сигнала при начальном соединении.
         */
        this._keepAliveTimeout = 30000;
        this._store = store;
        this._dlgService = dlgService;
        this._translateService = translateService;
        this._store.select(websocketActiveSubscriptionsSelector)
            .subscribe(function (val) { return _this._activeWSSubscriptions = val; });
    }
    //endregion
    //region Public
    /**
     * Инициализация соединения по веб сокету.
     */
    OperatorWebsocketService.prototype.connect = function () {
        if (this._connection) {
            this._connection.close();
        }
        this._connection = new WebSocket(UrlUtils.webSocketUrl());
        this.init();
    };
    /**
     * Закрытие соединения по веб сокету.
     */
    OperatorWebsocketService.prototype.close = function () {
        if (this._connection) {
            this._connection.close();
        }
    };
    /**
     * Подписка на событие, передаваемое по вебсокету.
     *
     * @param event Событие, на которое происходит подписка.
     */
    OperatorWebsocketService.prototype.subscribe = function (event) {
        if (this._connection) {
            var subscriptionRequest = {
                type: WebsocketRequestType.SUBSCRIPTION,
                payload: { event: event }
            };
            this._connection.send(JSON.stringify(subscriptionRequest));
        }
    };
    /**
     * Отписка от события, передаваемого по вебсокету.
     *
     * @param event Событие, от которого происходит отписка.
     */
    OperatorWebsocketService.prototype.unsubscribe = function (event) {
        if (this._connection) {
            var subscriptionRequest = {
                type: WebsocketRequestType.UNSUBSCRIPTION,
                payload: { event: event }
            };
            this._connection.send(JSON.stringify(subscriptionRequest));
        }
    };
    //endregion
    //region Private
    /**
     * Инициализация событий, связанных с веб сокетом.
     */
    OperatorWebsocketService.prototype.init = function () {
        var _this = this;
        this._connection.onopen = function (event) {
            _this._store.dispatch(websocketActions.connected());
        };
        this._connection.onclose = function (event) {
            _this._store.dispatch(websocketActions.disconnected());
            if (!_this._keepAliveField) {
                _this._dlgService.openSimpleDlg({
                    headerKey: "operator.websocket.connectionLost.title",
                    text: _this._translateService.get("operator.websocket.connectionLost.text", { message: event.reason, code: event.code }),
                    disableClose: true,
                    closeBtnKey: "operator.websocket.error.reloadButton",
                    cancelCallback: function () { return window.location.reload(); },
                });
            }
        };
        this._connection.onmessage = function (event) { return _this.handleMessage(event); };
        this._keepAliveTimeId = setTimeout(function () { return _this._onFailedKeepAlive(); }, this._keepAliveTimeout);
    };
    /**
     * Обработка сообщения о событии, пришедшего по веб сокету.
     *
     * @param event Сообщение о событии.
     */
    OperatorWebsocketService.prototype.handleMessage = function (event) {
        var _this = this;
        var websocketEvent = JSON.parse(event.data);
        switch (websocketEvent.type) {
            case WebsocketEventType.KEEP_ALIVE: {
                var payload = websocketEvent.payload;
                this._keepAliveField = false;
                if (this._keepAliveTimeId) {
                    clearTimeout(this._keepAliveTimeId);
                }
                this._keepAliveTimeId = setTimeout(function () { return _this._onFailedKeepAlive(); }, payload.interval * OperatorWebsocketService.MAX_MISS_KEEP_ALIVE + 100);
                var keepAliveRequest = { type: WebsocketRequestType.KEEP_ALIVE };
                this._connection.send(JSON.stringify(keepAliveRequest));
                break;
            }
            case WebsocketEventType.ERROR: {
                var websocketErrorEvent = websocketEvent;
                var errorText = this._translateService.get("operator.websocket.error.text", { message: websocketErrorEvent.payload.message });
                this._dlgService.openSimpleDlg({
                    headerKey: "operator.websocket.error.title",
                    text: errorText,
                    disableClose: true,
                    closeBtnKey: "operator.websocket.error.reloadButton",
                    cancelCallback: function () { return window.location.reload(); },
                });
                break;
            }
            case WebsocketEventType.INACTIVE_OPERATOR_RELEASE_DOCUMENT: {
                var payload = websocketEvent.payload;
                var commonTime = moment.duration(payload.operatorInactivityTimeThreshold, "seconds");
                var splittingTime = moment.duration(payload.operatorSplittingInactivityTimeThreshold, "seconds");
                var msg = this._translateService.get("operator.websocket.inactivity.text", { commonTime: commonTime.minutes(), splittingTime: splittingTime.minutes() });
                this._dlgService.openSimpleDlg({
                    headerKey: "operator.websocket.inactivity.title",
                    text: msg,
                    disableClose: true,
                    closeBtnKey: "operator.websocket.error.reloadButton",
                    cancelCallback: function () { return window.location.reload(); },
                });
                // Чтобы следом не выводить сообщение о разрыве соединения.
                this._keepAliveField = true;
                clearTimeout(this._keepAliveTimeId);
                this._connection.close();
                break;
            }
            case WebsocketEventType.INACTIVE_OPERATOR_NOTIFY: {
                if (environment.inactiveOperatorNotificationSoundUrl) {
                    new Audio(environment.inactiveOperatorNotificationSoundUrl)
                        .play()
                        .catch(function () {
                        new Audio(environment.inactiveOperatorNotificationSoundUrl).play();
                    });
                }
                break;
            }
            default: {
                if (!this._activeWSSubscriptions.includes(websocketEvent.type)) {
                    this._store.dispatch(websocketActions.subscribe({ eventType: websocketEvent.type }));
                }
                this._store.dispatch(createAction(WebsocketUtils.getEventActionType(websocketEvent.type), function (e) { return e.payload; })(websocketEvent));
            }
        }
    };
    /**
     * Выполняет действие на провал теста соединения через KEEP_ALIVE
     */
    OperatorWebsocketService.prototype._onFailedKeepAlive = function () {
        this._keepAliveField = true;
        if (this._connection) {
            this._connection.close();
        }
        this._dlgService.openSimpleDlg({
            headerKey: "operator.websocket.connectionLost.title",
            text: this._translateService.get("operator.websocket.connectionLost.text", { message: null, code: null }),
            disableClose: true,
            closeBtnKey: "operator.websocket.error.reloadButton",
            cancelCallback: function () { return window.location.reload(); },
        });
    };
    //region Constants
    /**
     * Максимальное количество пропущенных KEEP_ALIVE событий.
     */
    OperatorWebsocketService.MAX_MISS_KEEP_ALIVE = 6;
    OperatorWebsocketService.ngInjectableDef = i0.defineInjectable({ factory: function OperatorWebsocketService_Factory() { return new OperatorWebsocketService(i0.inject(i1.Store), i0.inject(i2.DlgService), i0.inject(i3.TranslateService)); }, token: OperatorWebsocketService, providedIn: "root" });
    return OperatorWebsocketService;
}());
export { OperatorWebsocketService };
