import { createSelector } from "@ngrx/store";
import { WebsocketEventType } from "src/app/common/models/websocket/websocket-event-type";
import { websocketStateSelector } from "src/app/root/store/reducers/index";
import { WebsocketState } from "src/app/root/store/states/websocket.state";

/**
 * Объект селекторов состояния веб сокета.
 */
export const websocketConnectingSelector = createSelector(
    websocketStateSelector,
    (state: WebsocketState): boolean => state.connecting,
);

/**
 * Объект селекторов состояния веб сокета.
 */
export const websocketConnectedSelector = createSelector(
    websocketStateSelector,
    (state: WebsocketState): boolean => state.connected,
);

export const websocketActiveSubscriptionsSelector = createSelector(
    websocketStateSelector,
    (state: WebsocketState): WebsocketEventType[] => state.activeSubscriptions,
);
