import { WebsocketEventType } from "src/app/common/models/websocket/websocket-event-type";
import { WebsocketActionType } from "src/app/root/store/actions/websocket.actions";
import { WebsocketAction } from "src/app/root/store/actions/websocket.actions";
import { WebsocketState } from "src/app/root/store/states/websocket.state";

/**
 * Начальное состояние состояния веб сокета.
 */
export const initialState: WebsocketState = { connecting: true, connected: false, activeSubscriptions: [] };

/**
 * Обработчик событий, связанных с веб сокетом.
 *
 * @param state Состояние веб сокета.
 * @param action Событие.
 *
 * @return Обновлённое или то же состояние веб сокета.
 */
export function websocketReducer(state = initialState, action: WebsocketAction): WebsocketState {

    let result = state;

    switch (action.type) {

        case WebsocketActionType.CONNECT: {

            result = {...state, connecting: true, connected: false};
            break;
        }

        case WebsocketActionType.CONNECTED: {

            result = {...state, connecting: false, connected: true}
            break;
        }

        case WebsocketActionType.DISCONNECTED: {

            result = {...state, connecting: false, connected: false};
            break;
        }

        case WebsocketActionType.SUBSCRIBE: {

            if (!state.activeSubscriptions.includes(action.eventType)) {

                result = {
                    ...state,
                    activeSubscriptions: [...state.activeSubscriptions, action.eventType]
                }
            }
            break;
        }

        case WebsocketActionType.UNSUBSCRIBE: {

            result = {
                ...state,
                activeSubscriptions: state.activeSubscriptions.filter(
                    (type: WebsocketEventType) => type !== action.eventType
                ),
            }
            break;
        }
    }

    return result;
}
