import { Injectable } from "@angular/core";
import { createAction } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { ErrorWebsocketEventPayload } from "src/app/common/models/websocket/error-websocket-event-payload";
import { EventWebsocketRequestPayload } from "src/app/common/models/websocket/event-websocket-request-payload.model";
import { KeepAliveWebsocketEventPayload } from "src/app/common/models/websocket/keep-alive-websocket-event-payload";
import { WebsocketEventType } from "src/app/common/models/websocket/websocket-event-type";
import { WebsocketEvent } from "src/app/common/models/websocket/websocket-event.model";
import { WebsocketRequestType } from "src/app/common/models/websocket/websocket-request-type.model";
import { WebsocketRequest } from "src/app/common/models/websocket/websocket-request.model";
import { DlgService } from "src/app/common/services/dlg.service";
import { UrlUtils } from "src/app/common/utils/url.utils";
import { WebsocketUtils } from "src/app/common/utils/websocket.utils";
import { websocketActions } from "src/app/root/store/actions/websocket.actions";
import { websocketActiveSubscriptionsSelector } from "src/app/root/store/selectors/websocket.selectors";
import { RootState } from "src/app/root/store/states/root.state";
import { environment } from "src/environments/environment";
import { InactiveOperatorReleaseDocumentWebsocketEventPayload } from "src/app/common/models/websocket/release-document-websocket-event-payload copy";
import * as moment from "moment";

/**
 * Сервис для работы с веб сокетом.
 */
@Injectable({
    providedIn: "root"
})
export class OperatorWebsocketService {
    //region Constants

    /**
     * Максимальное количество пропущенных KEEP_ALIVE событий.
     */
    private static readonly MAX_MISS_KEEP_ALIVE = 6;

    //endregion
    //region Fields

    /**
     * Соединение по веб сокету.
     */
    private _connection: WebSocket;

    /**
     * ИД таймера для разрыва соединения при пропуске события Keep Alive.
     */
    private _keepAliveTimeId: number;

    /**
     * Проверка соединения провалена?
     */
    private _keepAliveField: boolean = false;

    /**
     * Список активных подписок на события вебсокета.
     */
    private _activeWSSubscriptions: WebsocketEventType[];

    /**
     * Состояние приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Сервис для работы с диалогами.
     */
    private readonly _dlgService: DlgService;

    /**
     * Сервис перевода.
     */
    private readonly _translateService: TranslateService;

    /**
     * Максимальное время ожидания Keep Alive сигнала при начальном соединении.
     */
    private readonly _keepAliveTimeout = 30000;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса работы с веб сокетом.
     *
     * @param store Хранилище состояния приложения.
     * @param dlgService Сервис для работы с диалогами.
     * @param translateService Сервис перевода.
     */
    constructor(store: Store<RootState>, dlgService: DlgService, translateService: TranslateService) {

        this._store = store;
        this._dlgService = dlgService;
        this._translateService = translateService;
        this._store.select(websocketActiveSubscriptionsSelector)
            .subscribe(val => this._activeWSSubscriptions = val);
    }

    //endregion
    //region Public

    /**
     * Инициализация соединения по веб сокету.
     */
    connect(): void {

        if (this._connection) {

            this._connection.close();
        }
        this._connection = new WebSocket(UrlUtils.webSocketUrl());
        this.init();
    }

    /**
     * Закрытие соединения по веб сокету.
     */
    close(): void {

        if (this._connection) {

            this._connection.close();
        }
    }

    /**
     * Подписка на событие, передаваемое по вебсокету.
     *
     * @param event Событие, на которое происходит подписка.
     */
    subscribe(event: string): void {

        if (this._connection) {

            const subscriptionRequest: WebsocketRequest<EventWebsocketRequestPayload> = {
                type: WebsocketRequestType.SUBSCRIPTION,
                payload: {event}
            };

            this._connection.send(JSON.stringify(subscriptionRequest));
        }
    }

    /**
     * Отписка от события, передаваемого по вебсокету.
     *
     * @param event Событие, от которого происходит отписка.
     */
    unsubscribe(event: string): void {

        if (this._connection) {

            const subscriptionRequest: WebsocketRequest<EventWebsocketRequestPayload> = {
                type: WebsocketRequestType.UNSUBSCRIPTION,
                payload: {event}
            };

            this._connection.send(JSON.stringify(subscriptionRequest));
        }
    }

    //endregion
    //region Private

    /**
     * Инициализация событий, связанных с веб сокетом.
     */
    private init(): void {

        this._connection.onopen = (event: Event) =>  {
            this._store.dispatch(websocketActions.connected());
        };
        this._connection.onclose = (event: CloseEvent) => {

            this._store.dispatch(websocketActions.disconnected());
            if (!this._keepAliveField) {

                this._dlgService.openSimpleDlg({
                    headerKey: "operator.websocket.connectionLost.title",
                    text: this._translateService.get(
                        "operator.websocket.connectionLost.text",
                        {message: event.reason, code: event.code},
                    ),
                    disableClose: true,
                    closeBtnKey: "operator.websocket.error.reloadButton",
                    cancelCallback: () => window.location.reload(),
                });
            }
        };
        this._connection.onmessage = (event: MessageEvent) => this.handleMessage(event);
        this._keepAliveTimeId = setTimeout(() => this._onFailedKeepAlive(), this._keepAliveTimeout);
    }

    /**
     * Обработка сообщения о событии, пришедшего по веб сокету.
     *
     * @param event Сообщение о событии.
     */
    private handleMessage(event: MessageEvent): void {

        const websocketEvent = JSON.parse(event.data) as WebsocketEvent<any>;

        switch (websocketEvent.type) {

            case WebsocketEventType.KEEP_ALIVE: {

                const payload: KeepAliveWebsocketEventPayload = websocketEvent.payload;
                this._keepAliveField = false;
                if (this._keepAliveTimeId) {

                    clearTimeout(this._keepAliveTimeId);
                }

                this._keepAliveTimeId = setTimeout(
                    () => this._onFailedKeepAlive(),
                    payload.interval * OperatorWebsocketService.MAX_MISS_KEEP_ALIVE + 100
                );

                const keepAliveRequest: WebsocketRequest<never> = { type: WebsocketRequestType.KEEP_ALIVE };
                this._connection.send(JSON.stringify(keepAliveRequest));

                break;
            }
            case WebsocketEventType.ERROR: {

                const websocketErrorEvent: WebsocketEvent<ErrorWebsocketEventPayload> =
                    websocketEvent as WebsocketEvent<ErrorWebsocketEventPayload>;

                const errorText: Observable<string> = this._translateService.get(
                    "operator.websocket.error.text",
                    { message: websocketErrorEvent.payload.message },
                );

                this._dlgService.openSimpleDlg({
                    headerKey: "operator.websocket.error.title",
                    text: errorText,
                    disableClose: true,
                    closeBtnKey: "operator.websocket.error.reloadButton",
                    cancelCallback: () => window.location.reload(),
                });
                break;
            }
            case WebsocketEventType.INACTIVE_OPERATOR_RELEASE_DOCUMENT: {

                const payload: InactiveOperatorReleaseDocumentWebsocketEventPayload = websocketEvent.payload;

                const commonTime = moment.duration(payload.operatorInactivityTimeThreshold, "seconds");
                const splittingTime = moment.duration(payload.operatorSplittingInactivityTimeThreshold, "seconds");

                const msg: Observable<string> = this._translateService.get(
                    "operator.websocket.inactivity.text",
                    { commonTime: commonTime.minutes(), splittingTime: splittingTime.minutes() },
                );

                this._dlgService.openSimpleDlg({
                    headerKey: "operator.websocket.inactivity.title",
                    text: msg,
                    disableClose: true,
                    closeBtnKey: "operator.websocket.error.reloadButton",
                    cancelCallback: () => window.location.reload(),
                });

                // Чтобы следом не выводить сообщение о разрыве соединения.
                this._keepAliveField = true;
                clearTimeout(this._keepAliveTimeId);

                this._connection.close();

                break;
            }
            case WebsocketEventType.INACTIVE_OPERATOR_NOTIFY: {

                if (environment.inactiveOperatorNotificationSoundUrl) {

                    new Audio(environment.inactiveOperatorNotificationSoundUrl)
                        .play()
                        .catch((): void => {

                            new Audio(environment.inactiveOperatorNotificationSoundUrl).play();
                        });
                }

                break;
            }
            default: {

                if (!this._activeWSSubscriptions.includes(websocketEvent.type)) {

                    this._store.dispatch(websocketActions.subscribe({eventType: websocketEvent.type}));
                }

                this._store.dispatch(
                    createAction(
                        WebsocketUtils.getEventActionType(websocketEvent.type),
                        (e: WebsocketEvent<any>) => e.payload
                    )(websocketEvent)
                );
            }

        }
    }

    /**
     * Выполняет действие на провал теста соединения через KEEP_ALIVE
     */
    private _onFailedKeepAlive(): void {

        this._keepAliveField = true;
        if (this._connection) {

            this._connection.close();
        }

        this._dlgService.openSimpleDlg({
            headerKey: "operator.websocket.connectionLost.title",
            text: this._translateService.get(
                "operator.websocket.connectionLost.text",
                {message: null, code: null},
            ),
            disableClose: true,
            closeBtnKey: "operator.websocket.error.reloadButton",
            cancelCallback: () => window.location.reload(),
        });
    }

    //endregion
}
